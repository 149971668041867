<script setup>
import { ref, computed } from "vue";
import deviceBreakpoint from "../../../mixins/deviceBreakpoint";
import axios from "@/gateway/backendapi";
import { ElLoading } from "element-plus";
import { ElMessage } from "element-plus";
// import axio from "axios";
import { useStore } from "vuex";

// Dummy data for cart items
const { mdAndUp, lgAndUp, xlAndUp } = deviceBreakpoint();
const store = useStore();
const paymentGatewayModal = ref(false);
const selectedCurrency = ref({});
const currentUser = computed(() => {
  if (
    !store.getters.currentUser ||
    (store.getters.currentUser && Object.keys(store.getters.currentUser).length == 0)
  )
    return "";
  return store.getters.currentUser;
});
const cartItems = ref([
  {
    id: 1,
    title: "Rediscovering Emily",
    price: 15000,
    quantity: 1,
  },
  {
    id: 2,
    title: "Rediscovering Emily",
    price: 15000,
    quantity: 1,
  },
  {
    id: 3,
    title: "Rediscovering Emily",
    price: 15000,
    quantity: 1,
  },
]);

const orderTotal = computed(() => {
  return (
    cartItems.value.reduce((acc, item) => acc + item.price * item.quantity, 0) + 1000
  );
});
const initializePayment = (paymentGateway) => {
  const loading = ElLoading.service({
    lock: true,
    text: "Please wait...",
    background: "rgba(255, 255, 255, 0.9)",
  });

  const payload = {
    // subscriptionPlanID: selectedSubscriptionPlan.value.id,
    paymentGateway: paymentGateway === 0 ? "Paystack" : "Flutterwave",
    // totalAmount: TotalAmount.value,
    durationInMonths: "12",
    currencyId: selectedCurrency.value.id,
  };
  axios.post("/api/Payment/InitializeSubscription", payload).then(({ data }) => {
    console.log(data);
    close.value.click();
    // initializedOrder.value = res.data;
    loading.close();
    if (data.status) {
      if (paymentGateway == 0) {
        payWithPaystack(data);
      } else {
        payWithFlutterwave(data);
      }
    }
  });
};
const payWithFlutterwave = (responseObject) => {
  console.log(responseObject, "flutterwave");

  let country = "";

  switch (selectedCurrency.value.shortCode) {
    case "KES":
      country = "KE";
      break;
    case "GHS":
      country = "GH";
      break;
    case "ZAR":
      country = "ZA";
      break;
    case "TZS":
      country = "TZ";
      break;

    default:
      country = "NG";
      break;
  }

  window.FlutterwaveCheckout({
    // public_key: process.env.VUE_APP_FLUTTERWAVE_TEST_KEY,
    public_key: process.env.VUE_APP_FLUTTERWAVE_PUBLIC_KEY_LIVE,
    tx_ref: responseObject.transactionReference,
    // amount: TotalAmount.value,
    // currency: selectedCurrency.value.shortCode,
    country: country,
    payment_options: "card,ussd",
    customer: {
      name: currentUser.value.churchName,
      email: currentUser.value.userEmail,
    },
    callback: (response) => {
      let trans_id = response.transaction_id;
      let tx_ref = response.tx_ref;
      subscriptionPayment(trans_id, tx_ref);
    },
    onclose: () => console.log("Payment closed"),
    customizations: {
      title: "Subscription",
      description: "Payment for Subcription ",
      // logo: churchLogo.value,
    },
  });
};

const payWithPaystack = (responseObject) => {
  let PaystackPop = {};
  // let handler = PaystackPop.setup({
  let handler = PaystackPop.setup({
    key: process.env.VUE_APP_PAYSTACK_PUBLIC_KEY_LIVE,
    // key: process.env.VUE_APP_PAYSTACK_API_KEY,
    email: "info@churchplus.co",
    // amount: TotalAmount.value * 100,
    // currency: selectedCurrency.value.shortCode,
    channels: ["card", "bank", "ussd", "qr", "mobile_money", "bank_transfer"],
    ref: responseObject.transactionReference,
    onClose: function () {
      ElMessage({
        type: "info",
        showClose: true,
        message: "You have cancelled the transaction",
        duration: 5000,
      });
    },
    callback: function (response) {
      let trans_id = response.trxref;
      let tx_ref = response.trxref;
      subscriptionPayment(tx_ref, trans_id);
    },
  });
  handler.openIframe();
};
const subscriptionPayment = async (trans_id, tx_ref) => {
  try {
    await axios
      .post(`/api/Payment/ConfirmSubscriptionPayment?id=${trans_id}&txnref=${tx_ref}`)
      .then((res) => {
        console.log(res);
        // display.value = true;
        // if (res.data) {
        //     paymentFailed.value = false;
        //     paymentDone.value = true;
        // } else {
        //     paymentFailed.value = true;
        // }
      })
      .catch((err) => {
        console.log(err);
        // display.value = true;
        // paymentFailed.value = true;
        // paymentDone.value = false;
      });
  } catch (error) {
    console.log(error);
    // paymentDone.value = false;
    // paymentFailed.value = true;
    ElMessage({
      type: "error",
      showClose: true,
      message: "Your Subscription Failed",
      duration: 5000,
    });
  }
};

const showPaymentGateway = () => {
  paymentGatewayModal.value = true;
};

// const goBack = () => {
//   // Logic to navigate back
//   window.history.back();
// };
</script>
<template>
  <!-- <div class="row">
    <div class="col-md-12 d-flex bg-black-2 py-4">
      <div
        class="d-flex col-md-3 align-items-center justify-content-center mr-2 text-white"
        @click="goBack"
      >
        <el-icon :size="25"><Back /></el-icon>
        <span class="ml-1">Back</span>
      </div>
      <div class="col-md-6 ml-3">
        <el-input
          class="w-100 rounded-border"
          :suffix-icon="Search"
          placeholder="Search items product & services"
          v-model="searchQuery"
          size="large"
        />
      </div>
    </div>
  </div> -->
  <div class="container-slim my-5">
    <h2>Checkout</h2>
    <div class="row mt-3">
      <div class="col-md-8">
        <div class="card bg-gray-500 p-5 mb-3">
          <label class="fw-400 s-24">Shipping Address</label>
          <el-input
            type="text"
            class="w-100 mb-3"
            placeholder="No 40, co-op Estate Lagos Nigeria"
          />

          <div class="s-24 fw-400 mt-4">Payment Information</div>
          <div class="d-flex px-0 mb-3">
            <img src="../../../assets/gatewayicon.png" alt="Mastercard" class="w-50" />
          </div>

          <div class="form-group mt-3">
            <label for="cardName" class="font-weight-600">Name on Card</label>
            <el-input type="text" class="w-100" placeholder="Name" />
          </div>
          <div class="form-group mt-2">
            <label for="cardNumber" class="font-weight-600">Card Number</label>
            <el-input type="text" class="w-100" placeholder="9858 25452 25452 25452" />
          </div>
          <div class="form-row mt-2">
            <div class="form-group col-md-6">
              <label for="expiration" class="font-weight-600">Expiration</label>
              <el-input type="text" class="w-100" placeholder="24/05" />
            </div>
            <div class="form-group col-md-6">
              <label for="cvv" class="font-weight-600">CVV</label>
              <el-input type="text" id="cvv" class="w-100" placeholder="123" />
            </div>
          </div>

          <div class="row mt-4">
            <div class="col-md-12 d-flex justify-content-center">
              <div class="col-md-6">
                <el-button
                  round
                  @click="showPaymentGateway"
                  size="large"
                  color="#FF5906"
                  class="text-white w-100 py-4"
                  >Confirm payment</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="card w-100 bg-gray-500 mb-3">
          <div class="p-4">
            <h4>Order summary</h4>
            <div
              v-for="item in cartItems"
              :key="item.id"
              class="d-flex justify-content-between align-items-center mb-2"
            >
              <div>
                <p class="mb-0 fw-500">{{ item.quantity }}x {{ item.title }}</p>
              </div>
              <div class="d-flex">
                <p class="mb-0 fw-400">NGN {{ item.price }}</p>
                <span class="text-danger"
                  ><el-icon :size="20"><Delete /></el-icon
                ></span>
              </div>
            </div>
          </div>
          <hr />
          <div class="px-4">
            <div class="d-flex justify-content-between">
              <p class="mb-0 fw-500">Delivery</p>
              <p class="mb-0 fw-400">NGN 1,000</p>
            </div>
            <div class="d-flex justify-content-between">
              <p class="mb-0 fw-500">Discount</p>
              <p class="mb-0 fw-400">NGN 0</p>
            </div>
          </div>
          <hr />
          <div class="px-4">
            <div class="d-flex justify-content-between font-weight-bold pb-3">
              <span>Order Total</span>
              <span>NGN {{ orderTotal }}</span>
            </div>
            <div class="d-flex align-items-cen">
              <div>
                <el-input type="text" class="w-100 mb-2" placeholder="Coupon" />
              </div>
              <div class="ml-2">
                <el-button size="large" class="text-white bg-black-2 py-3"
                  >Apply</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <el-dialog
      class=""
      style="border-radius: 25px"
      v-model="paymentGatewayModal"
      title=""
      :width="mdAndUp || lgAndUp || xlAndUp ? `50%` : `90%`"
    >
      <div class="row justify-content-center">
        <div class="col-md-10 col-11 mt-4 h-100 bg-white mb-5">
          <div class="row justify-content-center align-items-center">
            <div class="col-md-10 d-flex justify-content-center">
              <div class="text-head font-weight-bold h3 pl-2">Payment methods</div>
            </div>
            <div class="col-md-12 mt-2 d-flex justify-content-center">
              <div class="col-md-7 col-12 col-sm-8">
                <div class="col-sm-12 p-4 text-center continue-text">
                  Continue payment with
                </div>
              </div>
            </div>
            <div class="col-md-12 mt-2 d-flex justify-content-center">
              <div
                class="row justify-content-center c-pointer"
                @click="initializePayment(0)"
                v-if="currentUser.currency == 'NGN' || currentUser.currency == 'GHS'"
              >
                <div class="col-md-8 border py-3 row-button">
                  <div class="row c-pointer justify-content-center">
                    <div class="col-md-6">
                      <img
                        class="w-100"
                        src="../../../assets/mobileonboarding/paystack.png"
                        alt="paystack"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="row c-pointer mt-3 justify-content-center"
                @click="initializePayment(1)"
              >
                <div class="col-md-8 border py-2 row-button">
                  <div class="row c-pointer justify-content-center">
                    <div class="col-md-7">
                      <img
                        class="w-100"
                        src="../../../assets/mobileonboarding/flutterwave.png"
                        alt="flutterwave"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<style scoped>
.card {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
}
</style>
